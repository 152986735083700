<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="ecological-group">
    <template v-if="isPreview">
      <template v-for="(item, index) in propValue">
        <component
          :is="item.component"
          :id="'component' + item.id"
          :key="item.id"
          :allBtnPressMap="allBtnPressMap"
          :isPreview="true"
          :currentComps="currentComps"
          class="component preview-child-comp"
          :style="getComponentStyle(item, item.groupStyle, item.style)"
          :prop-value="item.propValue"
          :element="item"
          @changeCurComp="changeCurComp"
          @changeBtnPress="changeBtnPress"
        />
      </template>
    </template>
    <template v-else>
      <Shape
        v-for="(item, index) in propValue"
        :key="item.id"
        :default-style="item.style"
        :ecological-style="styleDatas"
        :isEcological="true"
        :ecological-index="componentIndex"
        :ecological-element="element"
        :style="getShapeStyle(item.groupStyle, item.style)"
        :active="item.id === (curComponent || {}).id"
        :element="item"
        :index="index"
        :class="{
          lock: item.isLock,
          commonZ: true
        }"
      >
        <component
          :is="item.component"
          :id="'component' + item.id"
          :key="item.id"
          class="component"
          :style="getComponentStyle(item, item.groupStyle, item.style)"
          :prop-value="item.propValue"
          :element="item"
        />
      </Shape>
    </template>
    <!-- <div v-for="item in propValue" :key="item.id">
      <component
        :is="item.component"
        :id="'component' + item.id"
        :key="item.id"
        class="component"
        :style="item.groupStyle"
        :prop-value="item.propValue"
        :element="item"
      />
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Shape from "../components/Editor/Shape";
import { getStyle } from "@/utils/style";

export default {
  props: {
    propValue: {
      type: Array,
      default: () => [],
    },
    element: {
      type: Object,
      default: () => {},
    },
    styleDatas: {
      type: Object,
      default: () => {}
    },
    isPreview: Boolean,
    componentIndex: {
      type: Number
    },
    currentComps: {
      type: String,
      require: false
    },
    allBtnPressMap: {
      type: Object,
      require: false
    }
  },
  components: {
    Shape
  },
  computed: {
    ...mapState(["curComponent"])
  },
  methods: {
    getComponentStyle(item, groupStyle, style) {
      let allStyle = {
        ...style
      }
      let baseStyle = this.getShapeStyle(groupStyle, style);
      let filters = (this.isPreview && item.component === 'v-button') ? ['backgroundImage', 'backgroundColor'] : [];
      let otherStyle = getStyle(allStyle, ["top", "left", "width", "height", "rotate"].concat(filters));
      if (this.isPreview) {
        return {
          ...baseStyle,
          ...otherStyle
        }
      } else {
        return otherStyle;
      }
    },
    getShapeStyle(style, itemStyle) {
      const result = {};
      ["width", "height", "top", "left", "rotate"].forEach((attr) => {
        if (["width", "height"].indexOf(attr) !== -1) {
          // 宽高不用百分比展示，用实际的宽高，避免移动时高度受父组件的影响
          result[attr] = itemStyle[attr] + 'px';
        // } else if (["top", "left"].indexOf(attr) !== -1) {
        //   // 位置得计算得出
        //   result[attr] = itemStyle[attr] - this.styleDatas[attr] + 'px';
        } else if (attr != "rotate") {
          result[attr] = style[attr];
        } else {
          result.transform = "rotate(" + style[attr] + "deg)";
        }
      });

      return result;
    },
    changeCurComp(datas) {
      this.$emit('changeCurComp', datas);
    },
    changeBtnPress(datas) {
      this.$emit('changeBtnPress', datas);
    }
  }
};
</script>

<style lang="scss" scoped>
.ecological-group {
  .preview-child-comp {
    position: absolute;
  }
  & > div {
    //合并时的样式问题,暂时注释掉
    // position: relative;
    // width: 100%;
    // height: 100%;

    .component {
      position: absolute;
    }
  }
}
</style>
